import BlockRender from '@src/blocks';
import { Layout } from '@src/components/Layout';
import { IPageBlocks } from '@src/models/IPageBlocks';
import ILayoutConfig from '@src/models/LayoutConfig.model';
import { Grid } from '@src/components/Grid';
import React, { FC } from 'react';
import { PAGE_CONTENT_TYPES } from '@src/const/node-const';
import { createBackgroundImageConfig } from '@src/utils/createBackgroundImageConfig';
import { getDataFellowBlocks } from '@src/utils/getDataFellowBlocks';
import { getDataBlogItems } from '@src/utils/getDataBlogItems';
import { getNextPrevNewsData } from '@src/utils/getNextPrevNewsData';
import { getDataLatestNews } from '@src/utils/getDataLatestNews';
import { getContentItemByRoute } from '@src/api-service/cms/get-content-item-by-route';
import { isContentSegmentUrl } from '@src/utils/is-content-segment-url-match';
import { getAllNodes } from '@src/api-service/cms/get-all-nodes';

interface PageProps {
  layoutConfig: any;
  blockConfig: IPageBlocks;
}

const HomePage: FC<PageProps> = ({ layoutConfig, blockConfig }) => {
  return (
    <Layout layoutConfig={layoutConfig} blockConfig={blockConfig}>
      <Grid size="l">
        {blockConfig?.fields?.blocks.map((block, blockIndex) => (
          <BlockRender
            block={block}
            key={`${block.system.contentType}-${blockIndex}`}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export default HomePage;

export async function getStaticProps() {
  const nodes = await getAllNodes();
  const pageUrl = nodes
    .find(page => page.contentType === PAGE_CONTENT_TYPES.homePage)
    .url.replace('/', '');
  const layoutConfig: ILayoutConfig = await getContentItemByRoute('');
  const pageBlocksData: IPageBlocks = await getContentItemByRoute(pageUrl);
  layoutConfig.fields.hamburgerMenus?.forEach(menuItem => {
    menuItem.fields.menu?.forEach((menu, menuIndex) => {
      menu.isActive = isContentSegmentUrl(
        menu.contentSegmentUrl,
        `/${pageUrl}`,
      );
      if (menuIndex && menu.isActive) {
        menuItem.fields.menu[0].isActive = true;
      }
    });
  });

  await getDataFellowBlocks(pageBlocksData);

  await getDataBlogItems(pageBlocksData);

  await getDataLatestNews(pageBlocksData);

  await getNextPrevNewsData(pageBlocksData, pageUrl);

  createBackgroundImageConfig(pageBlocksData);

  return {
    props: {
      layoutConfig: layoutConfig,
      blockConfig: pageBlocksData,
    },
  };
}
